<script setup>
import Interface from "@/components/BVI/HUD/Interface.vue";
import Dialog from "@/components/BVI/Dialog/Dialog.vue";
import DialogBubble from "@/components/BVI/Dialog/DialogBubble.vue";

import {onMounted, ref} from 'vue';
import authService from "../../../plugins/auth";
import {v4 as uuidv4} from "uuid";
import store from "@/store";
import Buttons from "@/components/BVI/Dialog/Buttons.vue";
import Message from "@/components/BVI/UI/Message.vue";
import router from "@/router";
import Button from "@/components/BVI/UI/Button.vue";
import metrika from "../../../plugins/metrika";

const isLoading      = ref(true);
const userId = ref(null);
const dialog = ref(null);
const screenIndex = ref(0);
const location = ref('open_space');
const getGameStatus = async () => {
  try {
    if (process.env.NODE_ENV == 'production') {
      await authService.getProfile();
      userId.value = await store.dispatch('getUserId');
    } else {
      userId.value = !localStorage.getItem('userId') ? uuidv4() : localStorage.getItem('userId');
    }
    store.dispatch('setUserIdAction', userId.value)
    await store.dispatch('loadGameStatusAction', userId.value)
    isLoading.value = false

    if (store.getters.getNeedGreetings) {
      chooseActivity({dialog: 'dialogAlexGreetings'})
    }

    if (!store.getters.getNeedFinal && store.getters.getNotComplitedActivities(location.value).length === 0 && !store.getters.getPrize) {
      chooseActivity({dialog: 'dialogAlexFinal'})
    }
  } catch (error) {
    console.log(error)
  }
}
const buttonAction = (button) => {
  switch (button.action.name) {
    case ('changeDialog'):
      screenIndex.value = 0;
      chooseActivity(button.action.arg);
      break;
    case ('setScreenAction'):
      screenIndex.value = button.action.arg;
      break;
    case ('selectProftestAction'):
      if (typeof button.action.arg.finish === 'string') {
        screenIndex.value = 0;
        chooseActivity({dialog: button.action.arg.finish});
      } else {
        store.commit('setUserOrientations', button.action.arg.orientation);
        screenIndex.value = button.action.arg.num;
      }
      break;
    case ('goToCourse'):
      store.dispatch('addComplitedActivity', 'dialogProftest');
      store.commit('setCurrentActivity', null);
      screenIndex.value = 0;
      window.open(store.getters.getUserOrientationsLink, "_blank");
      break;
    case ('closeDialog'):
      if (button.action.arg.deactivate) {
        store.dispatch('addComplitedActivity', store.getters.getCurrentActivity);
      }
      store.commit('setCurrentActivity', null);
      screenIndex.value = 0;
      if (store.getters.getNeedGreetings) {
        store.dispatch('setNeedGreetingsAction', false)
      }
      if (button.text === "Забрать подарок") {
        metrika('game_take_present')
        router.push("/bvi/prize/")
      }
      if (button.action.arg.goTo === 'KitchenScene') {
        location.value = 'kitchen';
      }
      if (button.action.arg.link) {
        window.open(button.action.arg.link, "_blank");
      }

      if (!store.getters.getNeedFinal && store.getters.getNotComplitedActivities('open_space').length === 0 && !store.getters.getPrize) {
        chooseActivity({dialog: 'dialogAlexFinal'})
      }

      if (location.value === 'open_space' && store.getters.getNotComplitedActivities('open_space').length == 0 && store.getters.getPrize) {
        chooseActivity({dialog: 'dialogToKitchen'})
      }
      break;
  }

  if (button.achievement) {
    addAchievment(button.achievement);
  }

  if (button.setStatuses) {
    updateStatuses(button.setStatuses)
  }
}

const chooseActivity = (button) => {
  store.commit('setCurrentActivity', button.dialog);
  dialog.value = store.getters.getDialog(store.getters.getCurrentActivity);
}

const addAchievment = (achievement) => {
  store.commit('setMessage', '<p class=\'h4\'>На рабочем столе<br>появился новый предмет</p>');
  store.dispatch('addAchievementAction', achievement);
  setTimeout(()=>{
    store.commit('setMessage', null);
  }, 2000)
}

const updateStatuses = (statuses) => {
  statuses.forEach(status=> {
    store.dispatch('updateStatusAction', status);
  });
}

const restartGame = async () => {
  try {
    await store.dispatch('restartGameAction');
    chooseActivity({dialog: 'dialogAlexGreetings'})
    location.value = 'open_space';
  } catch (e) {
    console.log(e)
  }
}

const getSecondPrize = async () => {
  window.open('https://t.me/addstickers/Odnazhdy_v_ofise', '_blank');
  await store.dispatch('setSecondPrizeAction', true);
  window.location.reload();
}

onMounted(async () => {
  store.state.getCurrentActivity = null;
  await getGameStatus();
  if (store.getters.getComplitedActivities('kitchen').length) {
    location.value = 'kitchen';
  } else {
    try {
      if (store.getters.getNotComplitedActivities(location.value).length == 0 && store.getters.getPrize ) {
        chooseActivity({dialog: 'dialogToKitchen'})
      }
    } catch (error) {
      console.log(error)
    }
  }
});


</script>

<template>
  <main id="office" class="bvi-layout" :style="
    store.getters.getCurrentActivity && store.getters.getDialog(store.getters.getCurrentActivity).bvi_background ?
      `background-image: url(${store.getters.getDialog(store.getters.getCurrentActivity).bvi_background})` :
      `background-image: url(${store.getters.getBVILocation(location).bg})`
  ">
    <div v-if="!isLoading" class="container bvi-no-styles">
      <Interface />
      <Message/>

      <Dialog
          v-if="store.getters.getCurrentActivity"
      >
        <template v-slot:dialog>
          <DialogBubble
              :name="dialog.title"
          >
           <p>{{store.getters.getScreen(dialog, screenIndex, true).dialogText}}</p>
          </DialogBubble>
        </template>
        <template v-slot:buttons>
          <Buttons
              :title="'Выберите ответ'"
              :buttons="store.getters.getScreen(dialog, screenIndex, true).buttons"
              @buttonClicked="(button)=>buttonAction(button)"
          />
        </template>
      </Dialog>
      <Dialog v-else-if="store.getters.getNotComplitedActivities(location).length > 0">
        <template v-slot:dialog>
          <DialogBubble
              :name="store.getters.getBVILocation(location).title"
          >
            <div v-html="store.getters.getBVILocation(location).bubble_text"></div>
          </DialogBubble>
        </template>
        <template v-slot:buttons>
          <Buttons
              v-if="store.getters.getNotComplitedActivities(location).length > 0 && location === 'open_space'"
              :title="'Куда пойти'"
              :buttons="store.getters.getNotComplitedActivities(location)"
              @buttonClicked="(button)=>chooseActivity(button)"
          />
          <Buttons
              v-if="location === 'kitchen'"
              :title="'Куда пойти'"
              :buttons="[store.getters.getNotComplitedActivities(location)[0]]"
              @buttonClicked="(button)=>chooseActivity(button)"
          />
        </template>
      </Dialog>
      <div v-if="store.getters.getNotComplitedActivities('kitchen').length == 0 && store.getters.getPrize && !store.getters.getSecondPrize" class="new-game bvi-no-styles message">
        <Button :button="{
          text: 'Получить приз'
        }"
                @click="getSecondPrize()"
        ></Button>
      </div>
      <div v-if="store.getters.getNotComplitedActivities('open_space').length == 0
                 && store.getters.getNotComplitedActivities('kitchen').length == 0
                 && store.getters.getPrize
                 && store.getters.getSecondPrize"
           class="new-game bvi-no-styles message"
      >
        <Button :button="{
          text: 'Играть еще раз'
        }"
        @click="restartGame()"
        ></Button>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
  .message {
    margin: 0px auto 50px;
  }
</style>